import {useTranslation} from 'react-i18next'
import {useEffect} from 'react'
import {PageContext} from 'gatsbyTypes'

const useAutoSelectLanguage = (pageContext: PageContext) => {
  const {i18n} = useTranslation()
  useEffect(() => {
    if (!pageContext.langKey) {
      console.error('LANGKEY is undefined')
    }
    if (i18n.language !== pageContext.langKey) {
      i18n.changeLanguage(pageContext.langKey)
    }
  }, [pageContext.langKey])
}

export default useAutoSelectLanguage

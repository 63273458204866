import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/MDXLayout.tsx";
import SEO from 'components/SEO';
import CauseHero from 'components/CauseHero';
import { graphql } from 'gatsby';
import { ContainerImageWide } from 'components/ContainerImageWide';
import { FoodAidContainer } from 'components/FoodAidContainer';
import { VideoContainer } from 'components/VideoContainer';
import { Embed } from 'theme-ui';
export const pageQuery = graphql`
  query AtlasRelief {
    heroImage: file(relativePath: {eq: "project-atlas-relief-hero.png"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    atlasFamily: file(relativePath: {eq: "atlas-family.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    atlasTeam: file(relativePath: {eq: "atlas-team.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="ATLAS Relief: Today, human beings are still dying because of the cold and isolation." description="In the Atlas Mountains in Morocco, every winter that comes is a new challenge for the population: the challenge of survival, in the most difficult weather conditions. The freezing cold and the heavy snowfall aggravate their suffering." mdxType="SEO" />
    <CauseHero purpose="atlas-relief" title="ATLAS Relief" text="Today, human beings are still dying because of the cold and isolation." data={props.data} pagePath={props.path} mdxType="CauseHero" />
    <div className="p-wrapper">
      <p>{`In the Atlas Mountains in Morocco, every winter that comes is a new challenge for the population: the challenge of survival, in the most difficult weather conditions. The freezing cold and the heavy snowfall aggravate their suffering. Hundreds of villages are cut off from the world, with almost no means of supply during the long winter months. Children, sick people, and pregnant women have no access to basic medical care.`}</p>
      <p>{`Every winter, UMMATY organizes the ATLAS RELIEF winter campaign. It consists of several humanitarian missions, during which our volunteers provide assistance to these people, in the most remote areas. `}<strong>{`Dozens of tons of food are distributed as well as warm clothes and blankets`}</strong>{` to fight the cold. Your donations and your support allow the survival of these villagers.`}</p>
    </div>
    <div className="p-wrapper">
  <VideoContainer width={['100%', '100%', '50%']} mdxType="VideoContainer">
    <Embed src="https://www.youtube.com/embed/I6EblP55ass" mdxType="Embed"></Embed>
  </VideoContainer>
    </div>
    <ContainerImageWide zIndex={-1} height={[200, 300, 400]} fluid={props.data.atlasFamily.childImageSharp.fluid} mdxType="ContainerImageWide"></ContainerImageWide>
    <div className="p-wrapper">
      <p>{`Before each mission, our teams of volunteers, in collaboration with our local partners, target a particular region. During the months and weeks preceding each operation, we carry out a detailed census of the families living in the targeted villages, in order to ensure that your aid really benefits those most in need. Not only do we select priority families, but we also assess their specific needs so that the aid distributed is in line with the number of family members, adults and children.`}</p>
      <p>{`UMMATY volunteers then proceed to the negotiation and purchase of products intended for distribution. Thanks to many years of experience, UMMATY has been able to build a network of suppliers guaranteeing the best quality, but also the best prices on the market. Among the values of UMMATY, there is Excellence, the fact of always seeking the best, in the way we provide our services to donors, but also and above all in the assistance provided to beneficiaries. That’s why we are proud to be able to distribute the best quality food, in quantities well above the average food baskets that other organizations can distribute.`}</p>
      <p>{`The UMMATY food basket is more than a simple basket or a symbolic aid: `}<strong>{`each family`}</strong>{` benefits from `}<strong>{`more than 100KG of varied food`}</strong>{` and from a minimum of `}<strong>{`3 good quality blankets`}</strong>{` which allow them to survive during these long winter months.`}</p>
    </div>
    <div className="p-wrapper">
  <FoodAidContainer content={[{
        title: 'Complete Food Basket:',
        description: '50Kg Soft Wheat Flour, 15Kg Hard Wheat flour, 10Kg Barley, 5L Oil, 5Kg Sugar, 5Kg Lentils, 5Kg Rice, 2Kg Chickpea, 5Kg Beans, 1.25Kg Powdered Milk, 1Kg Jam, 3 Tea Units',
        price: '70$ US',
        color: 'primary',
        backgroudColor: '#D3D3D4'
      }, {
        title: '3 Blankets',
        description: '',
        price: '30$ US',
        color: 'primary',
        backgroudColor: '#F4F7F8'
      }, {
        title: 'Sponsorship of a family:',
        description: '(including 1 complete food basket + 3 blankets)',
        price: '100$ US',
        color: 'white',
        backgroudColor: '#055064'
      }]} mdxType="FoodAidContainer"></FoodAidContainer>
    </div>
    <div className="p-wrapper">
      <p>{`While we can stay warm this winter, they are children and the elderly people who continue to suffer from these extremely difficult conditions. Let’s stand by them. A simple online donation can change their daily lives. You can make a donation, regardless of the amount, or also choose to `}<strong>{`sponsor one or several families`}</strong>{` to help them get through this winter.`}</p>
    </div>
    <ContainerImageWide zIndex={-1} height={[200, 300, 400]} fluid={props.data.atlasTeam.childImageSharp.fluid} mdxType="ContainerImageWide"></ContainerImageWide>
    <div className="p-wrapper">
      <p>{`The `}<strong>{`Prophet`}</strong>{` (peace be upon him) said: `}<strong>{`“Whoever relieves the hardship of a believer in this world, Allah will relieve his hardship on the Day of Resurrection.”`}</strong>{` Narrated by Muslim.`}</p>
      <p>{`Together, with UMMATY we show them that despite their isolation, they are not alone, that they are not left to their fate and that in front of these difficulties; `}<strong>{`your generosity makes it possible to move mountains`}</strong>{`.`}</p>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
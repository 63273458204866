/** @jsx jsx */
import {jsx} from 'theme-ui'
import Layout from './Layout'
import {PageProps} from 'gatsby'
import {PageContext} from 'gatsbyTypes'
import useAutoSelectLanguage from 'hooks/useAutoSelectLanguage'

interface Props extends PageProps<object, PageContext> {}

const MDXLayout: React.FC<Props> = ({children, pageContext}) => {
  useAutoSelectLanguage(pageContext)
  return (
    <Layout
      navMobileColor="#F4F7F8"
      sx={{
        '.p-wrapper': {
          width: [295, '80%', 1440 - 204 * 2],
          maxWidth: '90%',
          mx: 'auto',
          mb: 50,
          h2: {
            mt: 70,
          },
        },
      }}
    >
      {children}
    </Layout>
  )
}

export default MDXLayout
